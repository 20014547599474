* {
  box-sizing: border-box;
}
body {
  font-size: smaller;
}
.container {
  display: grid;
  justify-items: center;
  width: 90%;
  margin: auto;
  margin-bottom: 2%;
}
.new-container {
  padding-left: 5%;
  padding-right: 5%;
}
.small-box {
  position: absolute;
  background-color: white;
}
.box1,
.box2 {
  width: 7%;
  height: 6%;
  background-color: rgb(74, 197, 74);
}
.box1 {
  top: 0;
  left: 0;
  border-radius: 0px 0px 131% 0px;
}
.box2 {
  top: 0;
  right: 0;
  border-radius: 0px 0px 0px 131%;
}
.header {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: 20px;
}
.logo {
  display: grid;
  justify-content: start;
}
.t-logo {
  width: 250px;
}
.contact {
  display: grid;
  justify-content: end;
  grid-template-rows: 30px 30px 30px 30px;
}
.contact img {
  display: inline-block;
  width: 17px;
  margin-right: 5px;
}
hr {
  height: 2px;
  background-color: black;
}
.title {
  width: 100%;
  margin: auto;
  padding: 11px 35px;
  letter-spacing: 2px;
  color: white;
}
h2 {
  width: 30%;
  padding: 9px 9px;
  text-align: center;
  background-color: rgb(59, 131, 59);
  border-radius: 9px;
  margin: 0 auto;
}
.head-container {
  display: grid;
  grid-template-columns: 50% 50%;
}
.second-head-container {
  display: grid;
  justify-content: end;
}
.first-body-container {
  line-height: 2px;
  background-color: rgb(74, 197, 74);
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  justify-items: center;
}
.second-body-container {
  line-height: 10px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  justify-items: center;
}
.second-body-container .apartment-address {
  line-height: normal;
}
.box {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 3;
}
.width {
  width: 100%;
}
.last-container {
  margin-top: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.first-last-container {
  padding-top: 40px;
}
.lifestyle {
  margin-top: 40px;
}
ul {
  padding: 0;
}
.title2 {
  width: 100%;
  margin: auto;
  padding: 11px 35px;
  border-radius: 9px;
  background-color: rgb(59, 131, 59);
  letter-spacing: 2px;
  color: white;
}
h4 {
  text-align: center;
  background-color: rgb(59, 131, 59);
  margin: 0;
}
.footer-container {
  width: 100%;
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 40px;
  margin-right: 5%;
}
button {
  font-size: 120%;
  font-weight: bolder;
  user-select: none;
  border: solid 2px rgba(73, 71, 71, 0);
  border-radius: 5px;
  letter-spacing: 1px;
  padding: 4px 8px;
  color: white;
  padding: 15px 30px;
  box-shadow: none;
  transition: all 0.3s;
}

.edit {
  background-color: rgb(251, 123, 18);
  transition: 0.5s;
}
.print {
  background-color: rgba(15, 105, 239, 0.816);
  width: 170px;
  transition: all 0.5s;
}
.edit:hover {
  color: rgb(251, 123, 18);
  background-color: white;
  border: solid 2px rgb(251, 123, 18);
  box-shadow: none;
  transition: all 0.3s;
}
.print:hover {
  color: rgba(15, 105, 239, 0.816);
  background-color: white;
  border: solid 2px rgba(15, 105, 239, 0.816);
  box-shadow: none;
  transition: all 0.3s;
}
.form {
  margin-top: 10%;
  width: 90%;
  border-radius: 20px;
  display: grid;
  box-shadow: 0px 0px 20px rgb(73, 71, 71);
  margin-bottom: 10%;
}
.form-body {
  width: 90%;
  margin: auto;
  padding-bottom: 4%;
  display: grid;
}
.form .box1 {
  box-shadow: 0px 0px 8px rgb(73, 71, 71);
  width: 10%;
  height: 20%;
}
.form .box2 {
  box-shadow: 0px 0px 8px rgb(73, 71, 71);
  width: 10%;
  height: 20%;
}
.main-form {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
  font-size: 15px;
}
.main-form input {
  font-size: 15px;
  height: 60px;
  border-radius: 6px;
  background-color: rgba(237, 233, 233, 0.767);
  border: 1px solid white;
  padding: 10px;
}
.in-cont {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10%;
}
.preview {
  width: 100%;
  margin-top: 5%;
  display: grid;
}
.preview button {
  padding: 15px 30px;
  border-radius: 8px;
  background-color: rgba(15, 105, 239, 0.816);
  box-shadow: none;
  transition: all 0.5s;
}
.preview button:hover {
  border: solid 2px rgba(15, 105, 239, 0.816);
  color: rgba(15, 105, 239, 0.816);
  background-color: white;
  box-shadow: none;
  transition: all 0.3s;
}
.one,
.two,
.three,
.four,
.five,
.six,
.seven {
  display: grid;
  grid-column-gap: 5%;
}
.one,
.three,
.five,
.six,
.seven {
  grid-template-columns: 1fr 1fr;
}
.two {
  grid-template-columns: 1fr 1fr 1fr;
}
.four {
  grid-template-columns: 1fr 1fr 1fr;
}
.first-hr {
  margin-top: 30px;
  margin-bottom: 10px;
}
.second-hr {
  margin-top: 50px;
  width: 100%;
}
